import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { Icon } from '../../../../common/components/Icon/Icon';
import styled from 'styled-components';
import { BadgeFirstIcon } from '../../../../common/components/Icon/Icons/BadgeFirst.icon';
import { Trans } from '@lingui/macro';
import { BadgeBlankIcon } from '../../../../common/components/Icon/Icons/BadgeBlank.icon';

export const MyBadgesDashboard: FC = () => {
  return (
    <>
      <Typography variant="h3" gutterBottom>
        <Trans>Moje Odznaky</Trans>
      </Typography>
      <BadgesWrapper>
        <div>
          <Icon Component={BadgeFirstIcon} size={63} />
          <Typography align="center">
            <Trans>První krok</Trans>
          </Typography>
        </div>

        <div>
          <Icon Component={BadgeBlankIcon} size={63} />
        </div>

        <div>
          <Icon Component={BadgeBlankIcon} size={63} />
        </div>
        <div>
          <Icon Component={BadgeBlankIcon} size={63} />
        </div>
        <div>
          <Icon Component={BadgeBlankIcon} size={63} />
        </div>
      </BadgesWrapper>
    </>
  );
};

const BadgesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
