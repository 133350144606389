import React, { FC } from 'react';
import Link from 'next/link';
import { Routes } from '../../../../common/enums/routes';
import { ProjectLogo } from '../../../../common/components/project/ProjectLogo';
import { Typography } from '@mui/material';
import { DashBoardStageProgress } from '../../../../common/components/project/StageProgress/DashBoardStageProgress';
import { getAllProjects_getAllProjects } from '../../../../apollo/queries/__generated__/getAllProjects';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { minWidth } from '../../../../common/styles/helpers';

type Props = { project: getAllProjects_getAllProjects };

export const ProjectDetails: FC<Props> = ({ project }) => {
  return (
    <Link href={Routes.Project + '/' + project.id}>
      <Wrapper fullWidth>
        <ProjectLogo className="image" url={project.projectLogoUrl} />
        <Typography variant="h4">{project.name}</Typography>

        <DashBoardStageProgress
          phases={project.diary[0].phases}
          className="span-2"
        />
      </Wrapper>
    </Link>
  );
};

const Wrapper = styled(Button)`
  width: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  grid-gap: 16px;
  justify-items: start;
  cursor: pointer;
  padding: 0;

  .span-2 {
    grid-column: span 2;
  }
`;
