import { FC } from 'react';
import styled from 'styled-components';
import { squareSize } from '../../styles/helpers';

type Props = {
  className?: string;
  size?: 'small' | 'large';
  url?: string;
};

export const ProjectLogo: FC<Props> = (props) => {
  const { className, size = 'regular', url } = props;

  const logoSizes = {
    small: 50,
    regular: 74,
    large: 150,
  };

  return (
    <StyledLogoWrapper className={className} size={logoSizes[size]}>
      <img src={url || '/raketka.png'} alt="" />
    </StyledLogoWrapper>
  );
};

const StyledLogoWrapper = styled.div<{ size: number }>`
  //width: 100%;
  //height: 100%;
  border: 1px solid #edf1f7;
  border-radius: 4px;
  overflow: hidden;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  display: flex;

  img {
    width: 100%;
    margin: auto;
  }
`;
