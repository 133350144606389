import { default as React, FC } from 'react';
import { ProgressBar } from './stageProgress.styles';
import { getAllProjects_getAllProjects_diary_phases } from '../../../../apollo/queries/__generated__/getAllProjects';
import styled from 'styled-components';
import { minWidth } from '../../../styles/helpers';
import { Typography } from '@mui/material';

type DashBoardStageProgressProps = {
  phases: getAllProjects_getAllProjects_diary_phases[];
  className?: string;
};

export const DashBoardStageProgress: FC<DashBoardStageProgressProps> = ({
  className,
  phases,
}) => {
  return (
    <DashboardGridWrapper className={className} $items={phases.length}>
      {phases.map((phase) => (
        <div key={phase.title}>
          <PhaseTitle align="center">{phase.label}</PhaseTitle>

          <ProgressBar
            progress={(phase.completedTasksCount / phase.tasksCount) * 100}
          />
        </div>
      ))}
    </DashboardGridWrapper>
  );
};

const DashboardGridWrapper = styled.div<{ $items: number }>`
  display: grid;
  grid-template-columns: repeat(${({ $items }) => Math.ceil($items / 2)}, 1fr);
  grid-template-rows: auto auto;
  width: 100%;
  grid-gap: 14px;

  ${minWidth.mobile} {
    grid-template-columns: repeat(${({ $items }) => $items}, 1fr);
  }
`;

const PhaseTitle = styled(Typography)`
  font-size: 12px;
`;
