import { FC } from 'react';
import { useQuery } from '@apollo/client';
import {
  getDashboardBanner,
  getDashboardBannerVariables,
} from '../../../apollo/cms/GraphCMS/__generatedTypes';
import { GET_DASHBOARD_BANNER } from '../../../apollo/cms/GraphCMS/getDashboardBanner';
import { useWhiteLabelContext } from '../../../common/containers/WhitelabeProvider';
import { GRAPH_CMS_CLIENT_NAME } from '../../../common/constants';
import { Hidden } from '@mui/material';
import { ga } from '../../../common/utils/ga';

type Props = {
  className?: string;
};

export const DashboardBanner: FC<Props> = ({ className }) => {
  const { loading, domainName } = useWhiteLabelContext();

  const { data } = useQuery<getDashboardBanner, getDashboardBannerVariables>(
    GET_DASHBOARD_BANNER,
    {
      skip: !domainName,
      variables: {
        name: [domainName],
      },
      context: {
        clientName: GRAPH_CMS_CLIENT_NAME,
      },
    }
  );

  const handleClick = () => {
    ga.event({
      action: 'click_on_banner_on_dashboard',
      category: 'category_test',
      label: 'label_test',
      value: 'value_test',
    });
  };

  if (!data?.dashboardBanners) {
    return null;
  }

  return (
    <div className={className}>
      <a
        onClick={handleClick}
        href={data.dashboardBanners[0]?.link}
        target="_blank"
        rel="noreferrer noopener"
      >
        <Hidden smUp>
          <img
            src={data.dashboardBanners[0]?.mobileImage.url}
            alt=""
            style={{ width: '100%' }}
          />
        </Hidden>

        <Hidden smDown>
          <img
            src={data.dashboardBanners[0]?.desktopImage.url}
            alt=""
            style={{ width: '100%' }}
          />
        </Hidden>
      </a>
    </div>
  );
};
