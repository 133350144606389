import { gql } from '@apollo/client';

export const GET_DASHBOARD_BANNER = gql`
  query getDashboardBanner($name: [String!]) {
    dashboardBanners(where: { domains_contains_some: $name }) {
      id
      name
      stage
      desktopImage {
        id
        stage
        width
        height
        size
        url
      }
      id
      link
      mobileImage {
        id
        stage

        width
        height
        size
        url
      }
    }
  }
`;
