import React from 'react';
import styled from 'styled-components';

import { MainLayout } from '../common/containers/MainLayout/MainLayout';
import { Card } from '@mui/material';

import { NextPage } from 'next';
import { minWidth } from '../common/styles/helpers';

import { MyProjectsDashboard } from './dashboard/components/MyProjects';
import { MyBadgesDashboard } from './dashboard/components/MyBadgesDashboard';
import { EmailVerificationInfo } from '../common/components/auth/EmailVerificationInfo';

import { t } from '@lingui/macro';
import { DashboardBanner } from './dashboard/components/DashboardBanner';

const Dashboard: NextPage = () => {
  return (
    <MainLayout protectedRoute title={t`Dashboard`}>
      <DashboardWrapper>
        <EmailVerificationInfo className="span-two" />

        <Card>
          <MyProjectsDashboard />
        </Card>

        <Card>
          <MyBadgesDashboard />
        </Card>

        <DashboardBanner className="span-two" />
      </DashboardWrapper>
    </MainLayout>
  );
};

export default Dashboard;

export const DashboardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;

  ${minWidth.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
    .span-two {
      grid-column: span 2;
    }
  }
`;
