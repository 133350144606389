import styled, { css } from 'styled-components';
import { colors } from '../../../styles/colors';
import Button from '@mui/material/Button';
import { squareSize } from '../../../styles/helpers';

export const ProgressBar = styled.div<{ progress: number }>`
  background: ${colors.lightBlue};
  border-radius: 5px;
  grid-column: 1 / -1;

  height: 10px;
  width: 100%;

  ::after {
    content: '';
    background: linear-gradient(
      to right,
      ${colors.brand} 0%,
      ${colors.green} 100%
    );
    border-radius: 5px;
    display: block;
    height: 100%;
    width: ${({ progress }) => `${progress}%`};
  }
`;

export const PhaseProgressHeader = styled(Button)<{ selected: boolean }>`
  position: relative;
  color: ${(props) => (props.selected ? colors.white : colors.black)};
  cursor: pointer;
  background-color: ${(props) => props.selected && colors.brand};
  padding: 20px 10px 10px;
  border-radius: 6px;
  border: 1px solid #d8e2f0;

  z-index: 1;

  &:after {
    content: '';
    display: block;
    ${squareSize('16px')}
    background-color: transparent;
    position: absolute;
    bottom: 8px;
    background-color: transparent;
    left: 50%;
    transform: rotate(45deg);
    z-index: -1;
  }

  ${(props) =>
    props.selected &&
    css`
      &:after {
        transition: 0.6s;
        background-color: ${colors.brand};
        transform: rotate(45deg) translateY(20px);
      }

      &:hover {
        background-color: ${colors.brandLight};
        &:after {
          background-color: ${colors.brandLight};
        }
      }
    `}

  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, auto);
  grid-row-gap: 10px;

  .span-three {
    grid-column: span 3;
    justify-self: end;
  }
`;
