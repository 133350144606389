import { gql } from '@apollo/client';

export const GET_ALL_PROJECTS = gql`
  query getAllProjects {
    getAllProjects {
      id
      name
      phase
      updatedAt
      projectLogoUrl
      diary {
        phases {
          title
          label
          tasksCount
          completedTasksCount
        }
      }
      user {
        id
        profilePicture
      }
    }
  }
`;
